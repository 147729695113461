import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import { HelpPage } from './HelpPage';
import HomePage from './HomePage';
import NoMatch from './NoMatch';
import { OnBoard } from './Onboard';
import Manual from './Manual';
import OnBoardRedirect from './OnboardRedirect';
import { OnBoardComplete } from './OnboardComplete';
import { LanguageEnum, isLanguageValid } from './helpers/LanguageHelper';
import HelpRedirect from './HelpRedirect';
import { CountryConfig } from './interfaces/Config';
import { CountryEnum, isCountryValid } from './helpers/CountryHelper';
import LegacyOnBoardRedirect from './LegacyOnBoardRedirect';
import LegacyOnBoardCompleteRedirect from './LegacyOnBoardCompleteRedirect';
import LegacyManualRedirect from './LegacyManualRedirect';
import PageSelector from './PageSelector';
import { SelfReset } from './SelfReset';
import SelfResetRedirect from './SelfResetRedirect';

function App() {
  const countries: CountryConfig[] = JSON.parse(process.env.REACT_APP_COUNTRIES || '[]');

  const location = useLocation();
  
  useEffect(() => {
    updateTitle(location.pathname);
  }, [location.pathname]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<PageSelector countries={countries} />} />
        {countries.map((c) => {
          if (!isCountryValid(c.Country) || !isLanguageValid(c.AvailableLangs)) {
            console.error("Invalid Language");
            return <></>;
          } else return (
            <React.Fragment key={c.Country}>
              <Route path={`onboard/${c.Country}`} element={<LegacyOnBoardRedirect country={c.Country} />} />
              <Route path={`onboard/${c.Country}/manual`} element={<LegacyManualRedirect country={c.Country} />} />
              <Route path={`onboardComplete/${c.Country}`} element={<LegacyOnBoardCompleteRedirect country={c.Country} />} />
              <Route path={`/${c.Country}`}>
                <Route index element={<HomePage country={c.Country} />} />
                <Route path="help" element={<HelpRedirect country={c.Country as CountryEnum} />} />
                <Route path="onboard" element={<OnBoardRedirect country={c.Country as CountryEnum} />} />
                <Route path="selfReset" element={<SelfResetRedirect country={c.Country as CountryEnum} />} />
                <Route path="onboard/manual" element={<Manual country={c.Country as CountryEnum} />} />
                {c.AvailableLangs.map((lang) => (
                  <React.Fragment key={`${c.Country}-${lang}`}>
                    <Route path={`help/${lang}`} element={<HelpPage country={c.Country as CountryEnum} lang={lang as LanguageEnum} />} />
                    <Route path={`onboard/${lang}`} element={<OnBoard country={c.Country as CountryEnum} lang={lang as LanguageEnum} />} />
                    <Route path={`onboardComplete/${lang}`} element={<OnBoardComplete country={c.Country as CountryEnum} lang={lang as LanguageEnum} />} />
                    {/*c.SelfReset && */ <Route path={`selfReset/${lang}`} element={<SelfReset country={c.Country as CountryEnum} lang={lang as LanguageEnum} />} />}
                  </React.Fragment>
                ))}
                <Route path="*" element={<NoMatch country={c.Country as CountryEnum} />} />
              </Route>
            </React.Fragment>
          )
        })}
      </Routes>
    </div>
  );
}

function updateTitle(pathname: string) {
  let title = "Iveco Group User Activation";
  switch (pathname) {
    default:
      if (pathname.indexOf("onboard") !== -1) {
        title = "Iveco Group User Activation";
      } else if (pathname.indexOf("/selfReset") !== -1) {
        title = "Iveco Group Reset Password";
      }
      break;
  }
  document.title = title;
}

export default App;
